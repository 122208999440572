.dialog {
    position: relative;
}
.buttonGroupPages {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 2rem;
    width: fit-content;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
}
.buttonsPages {
    border-radius: inherit;
}
