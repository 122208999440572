body {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.root {
    width: '100%';
    height: '100vh';
    padding-bottom: '3rem';
    font-family: 'roboto';
}
.title {
    text-transform: 'uppercase';
    font-size: '2rem';
    font-weight: 'bold';
    margin-left: '2rem';
}
.toolbar {
    min-height: '6rem';
}
.login-button {
    margin-top: 'auto';
    margin-bottom: 'auto';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    padding: '2rem 4rem';
    font-size: '2rem';
    border-width: '3px';
    z-index: 100;
}
.ilustration {
    position: 'fixed';
    width: '100%';
    height: '60vh';
    opacity: '20%';
    top: '25%';
    left: 0;
    right: 0;
    bottom: 0;
}

.main-div {
    width: '100%';
    height: '100vh';
    padding: '3rem';
}
.error {
    font-size: '1.25rem';
    color: 'red';
}
.react-pdf__Page__canvas {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
