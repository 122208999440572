.root {
    width: 100%;
    height: 100vh;
    padding-bottom: 3rem;
    font-family: 'roboto';
}
.ilustration {
    position: fixed;
    width: 100%;
    height: 60vh;
    opacity: 20%;
    top: 25%;
    left: 0;
    right: 0;
    bottom: 0;
}

.mainDiv {
    width: 100%;
    height: 100vh;
    padding: 3rem;
}
.error {
    font-size: 1.25rem;
    color: red;
}
.retryBtn {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 3px;
    z-index: 100;
}
