.mainContainer {
    padding: 2rem;
}
.backBtn {
    margin: 2px;
}
.dropZone {
    max-width: 500px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.uploadBtn {
    position: relative;
    margin-bottom: 2px;
}

.root {
    flex-grow: 1;
}
.menuButton {
    margin-right: 2px;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.disabledPrimary {
    color: hsl(231, 48%, 65%);
}
.filterBtn {
    margin-right: 1px;
}

.toolbar {
    padding-left: 0;
}
.mainContainer {
    padding: 2rem;
}
.backBtn {
    margin: 2px;
}
.dropZone {
    max-width: 500px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.dropZoneText {
    margin-top: 69px !important;
}
.uploadBtn {
    position: relative;
    margin: 8px 0 !important;
}
.dialog {
    position: relative;
}
.buttonGroupPages {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 2rem;
    width: fit-content;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
}
.buttonsPages {
    border-radius: inherit;
}
