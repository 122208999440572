/* ensure all pages have Bootstrap CSS */
@import './variables';

$background-color: #f9fafc;

.app-component {
    background: $background-color;
    min-height: 100vh;
}

body {
    margin: 0;
}

@keyframes moveFirst {
    0% {
        transform: translate(0, 0);
    }
    16.66% {
        transform: translate(-10px, 0);
    }
    49.98% {
        transform: translate(2px, 0);
    }
    66.64% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes moveSecond {
    0% {
        transform: translate(0, 0);
    }
    16.66% {
        transform: translate(0, -10px);
    }
    49.98% {
        transform: translate(0, 2px);
    }
    80% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes moveThird {
    0% {
        transform: translate(0, 0);
    }
    16.66% {
        transform: translate(10px, 0);
    }
    49.98% {
        transform: translate(-2px, 0);
    }
    66.64% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes moveFourth {
    0% {
        transform: translate(0, 0);
    }
    16.66% {
        transform: translate(0, 10px);
    }
    49.98% {
        transform: translate(0, -2px);
    }
    66.64% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.firstPart {
    position: absolute;
    animation: moveFirst 3s linear infinite;
}
.secondPart {
    position: absolute;
    animation: moveSecond 3s linear infinite;
    animation-delay: 0.5s;
}
.thirdPart {
    position: absolute;
    animation: moveThird 3s linear infinite;
    animation-delay: 1s;
}
.fourthPart {
    position: absolute;
    animation: moveFourth 3s linear infinite;
    animation-delay: 1.75s;
}

.text-idy-default {
    color: $idy-color-default !important;
    font-family: 'Gotham-Black', sans-serif !important;
}
.text-idy-primary {
    font-family: 'Gotham-Black', sans-serif !important;
    font-weight: normal;
    color: $idy-color-primary !important;
}
.text-idy-info {
    font-family: 'Gotham-Book', sans-serif !important;
    color: $idy-color-info !important;
    padding: 0 20px 0 12px;
    cursor: default;
}

.link-idy-info {
    color: #5a567c !important;
    font-family: 'Gotham-Bold', sans-serif !important;
    text-decoration: underline;
    &:hover {
        color: #2b1f86 !important;
        text-decoration: none;
    }
}

.pointer {
    cursor: pointer;
}

.drag-and-drop-container {
    width: 300px;
    height: 300px;
    border: 2px dashed $idy-color-progress-secondary;
    border-radius: 2rem;
    &:focus {
        outline: none;
        background-color: rgba(148, 148, 211, 0.1);
    }
}

.btn-idy-primary {
    color: #fff;
    background-color: $idy-btn-color-default !important;
    font-family: 'Gotham-Bold', sans-serif !important;
    font-style: normal;
    letter-spacing: 1.09px;

    &:hover {
        color: rgb(224, 224, 224) !important;
        box-shadow: none !important;
        filter: drop-shadow(0 3px 15px rgba(109, 99, 255, 0.1)) !important;
    }
}

.btn-rounded {
    border-radius: 9000px;
}

.font-gotham-bold {
    font-family: 'Gotham-Bold', sans-serif !important;
    font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/*here should be mobile exclusive css values*/
@media only screen and (min-width: 5rem) {
    .idy-floating-logo {
        background: url('../style/icons/logo.svg') no-repeat center center;
        background-size: contain;
        width: 29px;
        height: 32px;
        margin: 0;
    }
    .idy-floating-logo-white {
        background: url('../style/icons/logo-white.svg') no-repeat center center;
        background-size: contain;
        width: 29px;
        height: 32px;
        margin: 0;
    }
    .idy-title-wrapped {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 250px;
        font-size: 1.5rem;
        line-height: 1.2;
        cursor: default;
    }
}

.error-text {
    color: red;
}

.fontS-1 {
    font-size: 0.885rem;
}

.dropzone {
    border: none;
    outline: none;
    &:focus {
        outline: none;
    }
}

/*here should be tablet exclusive css values*/
@media only screen and (min-width: 42rem) {
    .idy-floating-logo {
        width: 68px;
        height: 58px;
    }
    .idy-floating-logo-white {
        width: 68px;
        height: 58px;
    }
    .idy-title-wrapped {
        max-width: 350px;
        font-size: 1.75rem;
    }
    .il {
        height: 200px;
    }
}

/*here should be desktop exclusive css values*/
@media only screen and (min-width: 81.25rem) {
}
