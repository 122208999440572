.cellHeight {
    min-height: 29px;
}
.noPadding {
    padding: 0;
}
.pointer {
    cursor: pointer;
}

.root {
    flex-grow: 1;
    width: 100%;
}
.titles {
    margin: 2rem 0;
}

.root2 {
    flex-grow: 1;
    width: 100%;
    padding: 1rem;
}

.root3 {
    flex-grow: 1;
    padding: 1rem;
}
.filter {
    width: 40%;
    margin: 1rem !important;
}
.flexWrap {
    flex-wrap: wrap;
}
.tableContainer {
    margin-top: 1.5rem;
}
