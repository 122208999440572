.root {
    flex-grow: 1;
    padding: 1rem;
    position: relative;
}

.flexWrap {
    flex-wrap: wrap;
}

.edit {
    position: absolute !important;
    top: 0;
    right: 0;
}

.save {
    position: absolute !important;
    top: 40px;
    right: 0;
}
